import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import MembershipForm from "./pages/MembershipForm";
import LoginPage from "./pages/LoginPage";
// import "bootstrap/dist/css/bootstrap.min.css";



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<MembershipForm />} />
        <Route path="/signin" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}

export default App;
