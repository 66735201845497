import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./App.css";
import logo from "../assets/img/ractow.png";

const Navbar = () => {
  return (
    <nav className="navbar navbar-light bg-light w-100 p-3 justify-content align-center" style={{
          width: "60vw",
          maxWidth: "600px",
          borderRadius: "10px"
        }}>
      <img src={logo} alt="Logo" className="navbar-logo" />
      
      <div className="small">Merchant: <Link to='/signup'>Sign up</Link> | <Link to='/signin'>Sign in</Link></div>
    </nav>
  );
};

export default Navbar;